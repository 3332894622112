














































import { defineComponent, ref, PropType, watch } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import participantsClient from '@/clients/participantsClient'
import { LeaguePlayerInfoExt } from '@/lib/support/models/LeaguePlayerInfo/LeaguePlayerInfoExt'

export default defineComponent({
  name: 'RemovedParticipantInfo',
  components: {
    SelectInput,
    Loading,
  },
  props: {
    person: { type: Object as PropType<LeaguePlayerInfo>, required: false },
    upwardLeagueId: { type: String, required: true },
  },

  setup(props) {
    const extperson = ref<LeaguePlayerInfoExt | null>(null)
    const isLoading = ref(false)

    const loadPlayerInfo = async () => {
      isLoading.value = true
      extperson.value = await participantsClient.retrieveRemovedParticipantInfo(
        props.upwardLeagueId,
        props.person?.individualID ?? 0
      )
      isLoading.value = false
    }

    watch(
      () => props.person,
      () => {
        if (props.person != null) {
          loadPlayerInfo()
        }
      },
      { immediate: true }
    )

    return {
      extperson,
      isLoading,
    }
  },
})
