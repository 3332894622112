









































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'

import { namespace as participantStoreName } from '@/store/participants'
import { namespace as leagueStoreName } from '@/store/leagues'
import { namespace as partnerStoreName } from '@/store/partners'
import { namespace as gradeStoreName } from '@/store/gradeTypes'
import { namespace } from 'vuex-class'

const participants = namespace(participantStoreName)
const league = namespace(leagueStoreName)
const leagueAbstraction = namespace(leagueAbstractionNamespace)
const partner = namespace(partnerStoreName)
const gradeTypes = namespace(gradeStoreName)

/**types */
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { League } from '@/GeneratedTypes/League'
import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import { PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import {
  DataTableSelection,
  DataTablePagination,
  DataTableRemovedPagination,
} from '@/models/DataTable/DataTableSelection'

/** external data */
import { headers, headersByAge, headersRemovedParticipants } from './ext/participantHeaders'

/**components */
import FullBody from '@/components/FullBody.vue'
import SearchInput from '@/elements/SearchInput.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'
import EditBtn from '@/elements/DataTable/vue/EditBtn.vue'
import DeleteBtn from '@/elements/DataTable/vue/DeleteBtn.vue'
import AddBtn from '@/elements/DataTable/vue/AddBtn.vue'
import CheckMark from '@/elements/DataTable/vue/CheckMark.vue'

/** local components */
import TeamInfo from '../../../components/TeamInfo.vue'
import RemovedParticipantInfo from '@/components/RemovedParticipantInfo.vue'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import ConfirmUnDeleteModal from '@/components/ConfirmUnDeleteModal.vue'

import TypeProgramTab from '@/components/TypeProgramTab.vue'
import { LeagueProgams2LeagueListItem } from '@/lib/support/models/LeagueListItem/LeaguePrograms2LeagueListItem'
import {
  LeagueInfoMoreThanOneProgram,
  LeagueListItemSeed,
  getEmptyLeagueListItem,
} from '@/lib/support/models/LeagueListItem/data'

import ParticipantSearch from '@/components/ParticipantSearch.vue'
import ParticipantEmailPhoneSearch from '@/components/ParticipantEmailPhoneSearch.vue'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { PlayerId } from '@/lib/support/models/LeaguePlayer/data'
import teamsClient from '@/clients/teamsClient'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { useFeatureFlags } from '@/services/useFeatureFlags'
import Loading from '@/elements/Loading.vue'
import ParticipantTransfer, {
  ChurchListType,
} from '@/views/Programs/Participants/vues/ParticipantTransfer.vue'
import ParticipantImport from '@/views/Programs/Participants/vues/ParticipantImport.vue'
import credentialsService from '@/services/credentialsService'
import jwtService from '@/services/jwtService'
import { getProperGradeLabel } from '@/services/gradeService'
import store from '@/store'
import { leagueAbstractionNamespace } from '@/store/leagueAbstraction'
import { Partner } from '@/models/Partner'

import currency from '@/filters/currency'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

@Component({
  setup() {
    return { ...useFeatureFlags(), getProperGradeLabel }
  },
  components: {
    Loading,
    FullBody,
    ConfirmDeleteModal,
    ConfirmUnDeleteModal,
    HorizontalTabs,
    SearchInput,
    TeamInfo,
    RemovedParticipantInfo,
    TypeProgramTab,
    ParticipantSearch,
    ParticipantEmailPhoneSearch,
    ParticipantTransfer,
    ParticipantImport,
    DataTable,
    EditBtn,
    DeleteBtn,
    AddBtn,
    CheckMark,
  },
  methods: {
    LeagueInfoMoreThanOneProgram,
  },
  data() {
    return { headers, headersByAge, headersRemovedParticipants }
  },
})
export default class ParticipantsView extends Vue {
  searchTerm = ''
  showDelete = false
  showUnDelete = false

  setAccountOnParticipant = store.dispatch.participants.setAccountOnParticipant
  clearCurrentParticipant = store.commit.participants.clearCurrent
  clearCurrentRemovedParticipant = store.commit.participants.clearCurrentRemovedParticipant
  setCurrentStoreProgram = store.commit.participants.setCurrentProgram
  setSkipParticipantRefresh = store.commit.participants.setSkipParticipantListRefresh
  loadProgramParticipants = store.dispatch.participants.loadProgramParticipants
  loadRemovedParticipants = store.dispatch.participants.loadRemovedParticipants
  setCurrentParticipantByID = store.dispatch.participants.setCurrentParticipantByID
  setCurrentRemovedParticipantByID = store.dispatch.participants.setCurrentRemovedParticipantByID
  removeParticipant = store.dispatch.participants.removeParticipant
  unRemoveParticipant = store.dispatch.participants.unRemoveParticipant
  setPagination = store.commit.participants.setPagination
  setRemovedPagination = store.commit.participants.setRemovedPagination

  /** getters */
  @league.Getter('currentItem') league!: League
  @participants.Getter('participants') participants!: LeaguePlayerInfo[]
  @participants.Getter('removedParticipants') removedParticipants!: LeaguePlayerInfo[]
  @participants.Getter('loading') loading!: boolean
  @participants.Getter('currentItem') currentParticipant!: LeaguePlayerInfo
  @participants.Getter('currentRemovedParticipant') currentRemovedParticipant!: LeaguePlayerInfo
  @participants.Getter('currentProgram') currentStoreProgram!: string
  @participants.Getter('status') status!: string
  @participants.Getter('skipParticipantListRefresh') skipRefresh!: boolean
  @participants.Getter('pagination') pagination!: DataTablePagination
  @participants.Getter('removedPagination') removedPaginaton!: DataTableRemovedPagination
  @partner.Getter('partners') partners!: Partner[]
  @gradeTypes.Getter('byUpwardTypeId') getUpwardGradeTypeID!: (typeID: string) => UpwardGradeTypeID | null

  @leagueAbstraction.Getter('currentItem') leagueAbstraction!: League
  @leagueAbstraction.Getter('programType') programType!: UpwardOpportunityTypes
  @leagueAbstraction.Getter('isUpwardSelect') isUpwardSelect!: boolean
  @leagueAbstraction.Getter('isByAge') isByAge!: boolean

  private formerParticipantSearchActive = false
  private emailPhoneParticipantSearchActive = false
  private teamInfo: DivisionTeamInfo[] = []
  private showRemovedParticipants = false

  async refreshParticipants() {
    await this.loadProgramParticipants({ leagueId: this.$route.params.id, shouldForceRefresh: true })
    await this.loadRemovedParticipants({ leagueId: this.$route.params.id, shouldForceRefresh: true })
  }

  /***
   * Route changing logic, looking at handling delete, and index, Former mostly,
   * for delete we need a participant id.
   */
  @Watch('$route', { immediate: true, deep: true })
  async routeChange(to: Route) {
    this.clearCurrentParticipant()
    this.clearCurrentRemovedParticipant()

    if (this.leagueAbstraction.programs?.length == 1) {
      this.currentProgram = this.leagueAbstraction.programs[0].typeProgramID
    }

    try {
      if (to?.params?.pid) {
        if (to.name?.startsWith('un-delete-participant')) {
          await this.setCurrentRemovedParticipantByID({
            id: parseInt(to.params.pid),
            typeProgramID: this.currentProgram,
          })
        } else {
          await this.setCurrentParticipantByID({
            id: parseInt(to.params.pid),
            typeProgramID: this.currentProgram,
          })
        }
      }

      if (to.name?.startsWith('delete-participant') && to.params?.pid) {
        this.deleteParticipant()
        return
      }

      if (to.name?.startsWith('un-delete-participant') && to.params?.pid) {
        this.unDeleteParticipant()
        return
      }

      if (to.name?.startsWith('former-participant')) {
        this.addFormerParticipant()
        return
      }
      if (to.name?.startsWith('search-participant')) {
        this.addEmailPhoneParticipant()
        return
      }
      if (to.name?.startsWith('transfer-participant')) {
        this.transferParticipant()
        return
      }
      if (to.name?.startsWith('import-participant')) {
        this.importParticipant()
        return
      }
    } catch (e) {
      await this.$router.push('../list')
      throw new Error(e.message)
    }

    //else...
    this.formerParticipantSearchActive = false
    this.emailPhoneParticipantSearchActive = false
    this.importParticipantActive = false
    this.transferParticipantActive = false
  }
  addFormerParticipant() {
    this.formerParticipantSearchActive = true
  }

  addEmailPhoneParticipant() {
    this.emailPhoneParticipantSearchActive = true
  }

  private transferParticipantActive = false
  transferParticipant() {
    this.transferParticipantActive = true
  }

  private importParticipantActive = false
  importParticipant() {
    this.importParticipantActive = true
  }

  get isCamp() {
    return this.programType == UpwardOpportunityTypes.CAMP
  }

  churches: ChurchListType[] = []
  @Watch('league', { immediate: true })
  leagueUpdated() {
    if (this.partners) {
      this.churches = this.partners
        .map((a) => {
          return {
            id: a.accountNumber ?? '',
            name: a.name,
          }
        })
        .filter((b) => b.id != this.account)
    }
  }

  @Watch('filteredRemovedParticipants', { deep: true })
  removedParticipantsUpdated() {
    if (this.filteredRemovedParticipants.length <= 0) {
      this.showRemovedParticipants = false
    }
  }

  async participantTransfer(a: number[], toAccount: string) {
    await this.setAccountOnParticipant({
      leagueID: this.leagueAbstraction.upwardLeagueID ?? '',
      ids: a,
      newAccount: toAccount,
    })
  }

  async participantImportCleanClose() {
    this.importParticipantActive = false
    await this.$router.push('list')
  }
  async participantImportDirtyClose() {
    this.importParticipantActive = false
    this.refreshParticipants()
    await this.$router.push('list')
  }
  /***
   * Update state on if the transfer is open
   */
  async participantTransferClosed() {
    this.transferParticipantActive = false
    await this.$router.push('list')
  }

  async searchCanceled() {
    this.formerParticipantSearchActive = false
    this.emailPhoneParticipantSearchActive = false
    await this.$router.push('list')
  }
  /**
   * Called by find when a participant is selected
   */
  async participantFound({ id, leagueID }: { id: PlayerInfoIDType; leagueID: UpwardLeagueIDType }) {
    try {
      await this.setCurrentParticipantByID({ id: id, typeProgramID: this.currentProgram })
      await this.$router.push('edit/' + id)
    } catch (error) {
      // not an existing participant
      await this.$router.push(`copy/${id}/${leagueID}`)
    }
    this.refreshParticipants()
  }

  onPagination(data: DataTablePagination) {
    this.setPagination({ pagination: data })
  }

  onRemovedPagination(data: DataTableRemovedPagination) {
    this.setRemovedPagination({ removedPagination: data })
  }

  get account() {
    return credentialsService.getPrimaryAccountFromCredentials(jwtService.getCredentialsFromStoredAuthToken())
  }

  participantProgramCount(id: number) {
    return this.participants.filter((x) => x.individualID == id).length
  }

  /***
   * Visible participants are influenced by the current tab
   */
  get visibleParticipants() {
    const participants = this.participants
      .map((p) => {
        return {
          ...p,
          prettyName: `${p.lastName}, ${p.firstName}`,
          prettyOwed: currency(p.amountExpectedToPay - p.amountPaid),
          prettyPaid: currency(p.amountPaid),
          ageByCutoff: this.getUpwardGradeTypeID(p.typeGradeID!)?.ageByCutoff ?? '',
        }
      })
      .sort((a, b) => (`${a.lastName}, ${a.firstName}` > `${b.lastName}, ${b.firstName}` ? 1 : -1))

    const program = this.programByIndex(this.currentProgram)
    if (program && this.isLeague) {
      return participants.filter(
        (x) => program.typeProgramID == x.typeProgramID && this.account?.trim() == x.accountNumber?.trim()
      )
    }
    return participants
  }

  get visibleRemovedParticipants() {
    const removedParticipants = this.removedParticipants
      .map((p) => {
        return {
          ...p,
          prettyName: `${p.lastName}, ${p.firstName}`,
          prettyOwed: currency(p.amountExpectedToPay - p.amountPaid),
          prettyPaid: currency(p.amountPaid),
          ageByCutoff: this.getUpwardGradeTypeID(p.typeGradeID!)?.ageByCutoff ?? '',
        }
      })
      .sort((a, b) => (`${a.lastName}, ${a.firstName}` > `${b.lastName}, ${b.firstName}` ? 1 : -1))

    const program = this.programByIndex(this.currentProgram)
    if (program && this.isLeague) {
      return removedParticipants.filter(
        (x) => program.typeProgramID == x.typeProgramID && this.account?.trim() == x.accountNumber?.trim()
      )
    }
    return removedParticipants
  }

  get filteredParticipants() {
    return this.visibleParticipants.filter(
      (x) =>
        x.firstName &&
        x.lastName &&
        `${x.firstName} ${x.lastName!}`.toLocaleLowerCase().search(this.searchTerm.toLocaleLowerCase()) != -1
    )
  }

  get filteredRemovedParticipants() {
    return this.visibleRemovedParticipants.filter(
      (x) =>
        x.firstName &&
        x.lastName &&
        `${x.firstName} ${x.lastName!}`.toLocaleLowerCase().search(this.searchTerm.toLocaleLowerCase()) != -1
    )
  }

  get isLeague() {
    return this.$route.fullPath.indexOf('/league/') > 0
  }

  get upwId() {
    return this.$route.params.id
  }

  deleteParticipant() {
    this.showDelete = true
  }

  unDeleteParticipant() {
    this.showUnDelete = true
  }

  async confirmDeleteParticipant() {
    try {
      await this.removeParticipant({
        upwardLeagueID: this.upwId ?? '',
        id: PlayerId(this.currentParticipant),
      })
      this.refreshParticipants()
    } catch (e) {
      throw e
    } finally {
      await this.$router.push('../list')
    }
  }
  async cancelDeleteParticipant() {
    await this.$router.push('../list')
  }

  async confirmUnDeleteParticipant() {
    try {
      await this.unRemoveParticipant({
        upwardLeagueID: this.upwId ?? '',
        id: PlayerId(this.currentRemovedParticipant),
        typeProgramID: this.currentProgram,
      })
      this.refreshParticipants()
    } catch (e) {
      throw e
    } finally {
      await this.$router.push('../list')
    }
  }
  async cancelUnDeleteParticipant() {
    await this.$router.push('../list')
  }

  mounted() {
    this.setAccountOnParticipant = store.dispatch.participants.setAccountOnParticipant
    this.clearCurrentParticipant = store.commit.participants.clearCurrent
    this.clearCurrentRemovedParticipant = store.commit.participants.clearCurrentRemovedParticipant
    this.setCurrentStoreProgram = store.commit.participants.setCurrentProgram
    this.loadProgramParticipants = store.dispatch.participants.loadProgramParticipants
    this.loadRemovedParticipants = store.dispatch.participants.loadRemovedParticipants
    this.setCurrentParticipantByID = store.dispatch.participants.setCurrentParticipantByID
    this.removeParticipant = store.dispatch.participants.removeParticipant
    this.unRemoveParticipant = store.dispatch.participants.unRemoveParticipant
    if (!this.skipRefresh) {
      this.refreshParticipants()
    } else {
      this.setSkipParticipantRefresh({ flag: false })
    }
  }

  destroyed() {
    this.clearCurrentParticipant()
    this.clearCurrentRemovedParticipant()
  }

  /***
   * Returns the structure to define the tabs based on loaded league
   */
  /** index into this.leagues.programs[] variables  */

  get currentProgram(): string {
    return this.currentStoreProgram
  }

  set currentProgram(p: string) {
    this.setCurrentStoreProgram({ program: p })
  }

  get tabs(): LeagueListItem {
    if (this.isLeague && this.league && this.league.programs) {
      return LeagueProgams2LeagueListItem(this.league.programs)
    }
    return getEmptyLeagueListItem(new LeagueListItemSeed())
  }

  async setLocalCurrentParticipantID(id: PlayerInfoIDType) {
    await this.setCurrentParticipantByID({ id: id, typeProgramID: this.currentProgram })
    this.teamInfo = await teamsClient.retrieveTeamInfo({
      leagueID: this.league.upwardLeagueID ?? '',
      individualId: id,
    })
  }

  rowSelected(x: DataTableSelection<LeaguePlayerInfo>) {
    const id = PlayerId(x.item)
    if (id) {
      this.setLocalCurrentParticipantID(id)
    }
  }
  removedRowSelected(x: DataTableSelection<LeaguePlayerInfo>) {
    const id = PlayerId(x.item)
    if (id) {
      this.setCurrentRemovedParticipantByID({ id: id, typeProgramID: this.currentProgram })
    }
  }
  async rowDoubleClicked(x: DataTableSelection<LeaguePlayerInfo>) {
    await this.$router.push(`edit/${PlayerId(x.item)}`)
  }

  programByIndex(index: string): LeagueProgram | null {
    if (this?.league?.programs) {
      let id = this.league.programs.findIndex((x) => x.typeProgramID == index)
      if (id < 0) {
        id = 0
      }
      return this?.league?.programs![id] ?? null
    }
    return null
  }

  formerParticipantSearchSelected(pid: PlayerInfoIDType) {
    alert('selected former participant' + pid)
  }

  emailPhoneParticipantSearchSelected(pid: PlayerInfoIDType) {
    alert('selected email / phone participant' + pid)
  }

  editItem(i: LeaguePlayerInfo) {
    this.$router.push(`edit/${i.individualID}`)
  }
  deleteItem(i: LeaguePlayerInfo) {
    this.$router.push(`delete/${i.individualID}`)
  }
  unDeleteItem(i: LeaguePlayerInfo) {
    this.$router.push(`undelete/${i.individualID}`)
  }
}
